<template lang="html">
  <div>
    <div class="title-area mt-s">
      <h2 class="title-b">설정 / 사이트 설정</h2>
    </div>
    
    <div class="form-box mini-box mt-sm">
      <div class="form-item row-type">
        <label class="form-title">사이트 명</label>
        <div class="form-element">
          <input type="text" placeholder="사이트 명" class="element-input size-lg" v-model="siteName">
        </div>
      </div>


      <div class="form-item row-type">
        <label class="form-title">탐지 문자 전송 시간</label>
        <div class="form-element">
          <t-rich-select
            :options="detectMinuteList"
            class="border border-solid rounded-lg py-3 text-blueGray-500 border-gray-300 outline-none w-full"
            textAttribute="name"
            valueAttribute="id"
            :hideSearchBox="true"
            v-model="detectMinute"
          >
          </t-rich-select>
        </div>
      </div>


      <div class="form-item row-type">
        <label class="form-title">단말 로그 보관 기간</label>
        <div class="form-element">
          <t-rich-select
            :options="deviceLogDayList"
            class="border border-solid rounded-lg py-3 text-blueGray-500 border-gray-300 outline-none w-full"
            textAttribute="name"
            valueAttribute="id"
            :hideSearchBox="true"
            v-model="deviceLogDay"
          >
          </t-rich-select>
        </div>
      </div>


      <div class="form-item row-type">
        <label class="form-title">로고 이미지</label>
          <img :src="logoFileContent" alt="" class="mt-3 cursor-pointer" @click="deleteLogoFile()" />
          
          <div v-if="logoFileInput" class="box-file-upload mt-xs">
            <div class="text-sm font-bold txt-blue cursor-pointer text-right mt-1 mb-5" @click="retryLogoFile()">새로올리기</div>
            <div class="block text-center mb-2">
              <div class="flex justify-center items-center">
                <i class="icon-img ic-map-add-list-icon mr-3"></i>
              <div>{{ logoFileInput.name }}</div>
              </div>
            </div>
          </div>
          
          <div v-else class="box-file-upload mt-xs" @dragover="dragover" @dragleave="dragleave" @drop="drop">
            <input type="file" class="w-px h-px opacity-0 overflow-hidden absolute" @change="registLogoFile()" id="logoFile" ref="logoFile" accept=".jpg, .png, .gif" />
          
            <label for="logoFile" class="block cursor-pointer">
              <div class="flex flex-col justify-center items-center">
                <i class="icon-img ic-m-file-upload"></i>
                <span class="file-desc">이곳을 클릭하여 파일 업로드 or 파일 드래그</span>
                <span class="file-name">지원 파일 확장자 *.jpg, *.png, *.gif</span>
              </div>
            </label>
          </div>

      </div>
      <div class="mt-sm btn-area">
        <button type="button" class="element-btn bg-blue size-lg" @click="saveCompanyLogoInfo()">변경사항 저장</button>
      </div>
    </div>
  </div>
</template>

<script>
import { requestApi } from "@/utils/api"
import { mapGetters, mapActions } from "vuex"

import { detectMinuteList, deviceLogDayList } from '@/references/config'

export default {
  created () {
    this.companyGuid = this.getCompanyGuid
    this.getCompanyLogoInfo()
  },
  computed: {
    ...mapGetters(["getIsOverlay","getCompanyGuid"]),
  },
  data () {
    return {
      companyGuid: '',

      siteName: '',
      logoFileInput: null,
      logoFileContent: null,
      isDeleteLogoFile: false,

      detectMinuteList,
      detectMinute: 0,
      deviceLogDayList,
      deviceLogDay: 0,

    }
  },
  methods: {
    ...mapActions(["chgIsOverlay","chgIsAlert"]),

    async getCompanyLogoInfo () {
      let reqObj = {}
      reqObj.target = `/company/logoInfo/${this.companyGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.isDeleteLogoFile = false
        this.detectMinute = result.msg.retData.detectMinute
        this.deviceLogDay = result.msg.retData.deviceLogDay
        this.siteName = result.msg.retData.logoName
        this.logoFileContent = result.msg.retData.fileContent
      }
    },

    async saveCompanyLogoInfo () {
      let reqObj = {}
      reqObj.target = `/company/logoUpdate`;
      reqObj.method = "put";
      reqObj.params = {};
      
      const frm = new FormData();
      frm.append("companyGuid", this.companyGuid);
      frm.append("logoName", this.siteName);
      frm.append("isDeleteLogoFile", this.isDeleteLogoFile ? 'yes' : 'no')
      if(this.logoFileInput != null) frm.append("logoFileInput", this.logoFileInput);

      frm.append("detectMinute", this.detectMinute);
      frm.append("deviceLogDay", this.deviceLogDay);
      reqObj.data = frm;

      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.logoFileInput = null
        this.getCompanyLogoInfo()

        this.chgIsAlert({status:true,string:'등록 했습니다.',reload:true})
      }
    },

    deleteLogoFile () {
      this.logoFileContent = null
      this.isDeleteLogoFile = true
    },

    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.logoFile.files = event.dataTransfer.files;
      this.onChange();
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    onChange() {
      this.logoFileInput = this.$refs.logoFile.files[0];
    },
    registLogoFile () {
      this.onChange()
    },
    retryLogoFile () {
      this.logoFileInput = null
    },
    
  }
}
</script>
<style lang="">
  
</style>