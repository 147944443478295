<template lang="html">
  <div class="content-box">
    <div class="component-tab-a">
      <a @click="chgTabIndex(1)" class="tab-item cursor-pointer" :class="tabIndex === 1 ? 'active' : ''">사이트 설정</a>
      <a @click="chgTabIndex(2)" class="tab-item cursor-pointer" :class="tabIndex === 2 ? 'active' : ''">음원 설정</a>
    </div>
    
    <!-- 단말 관리 TAB START -->
    <div v-if="tabIndex === 1">
      <SiteSetting></SiteSetting>
    </div>
    <!-- 단말 관리 TAB END -->

    <!-- 펌웨어 관리 TAB START -->
    <div v-if="tabIndex === 2">
      <SiteSound></SiteSound>
    </div>
    <!-- 펌웨어 관리 TAB End -->

  </div>
</template>

<script>
// import { requestApi } from "@/utils/api"
import { mapGetters, mapActions } from "vuex"

import SiteSetting from "@/pages/setting/SiteSetting"
import SiteSound from "@/pages/setting//SiteSound"

export default {
  created () {
  },
  components: {
    SiteSetting,
    SiteSound,
  },
  computed: {
    ...mapGetters(["getIsOverlay"]),
  },
  data () {
    return {
      tabIndex: 1,
    }
  },
  methods: {
    ...mapActions(["chgIsOverlay"]),
    chgTabIndex (idx) {
      this.tabIndex = idx;
    }
  }
}
</script>
<style lang="">
  
</style>